import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { CommonService } from '../common/common.service';
import {appSetting} from '../app.config';
declare var $: any;
declare var document: any;
import * as _ from "lodash";

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.css']
})
export class ViewJobComponent implements OnInit {

  constructor(private meta: Meta,private commonService: CommonService,private activatedRoute:ActivatedRoute,private router: Router) {  
    
    var JobUrlSplit = this.router.url.split('/');
    console.log("JobUrlSplit", JobUrlSplit)
    if(JobUrlSplit[1] === "view"){
      this.showJobDetails = false;
    }else if(JobUrlSplit[3] === "emp-view"){
      this.showJobDetails = true;
    }

    
    var userRole = this.commonService.getToken('role');
    if (this.commonService.getToken('accessToken') && (userRole === "client_employer" || userRole === "reviewer")) {
  		this.showEmpLoginBtn = true;
  	}else	if (this.commonService.getToken('accessToken') && (userRole === "candidate")) {
  		this.showCandLoginBtn = true;
  	}else{
      $('.non-login').removeClass('jabri-main-content');
    }

    this.activatedRoute.params.subscribe( params => {
      ////console.log(params)
      if(params['id']){
        this.jobid = params['id'];
        this.getJobDetail(params['id']);
        //this.getAppliedCandidate(params['id']);
        this.getAllTagList(params['id']);
        
      }else{
        this.router.navigate(['/job-list']); 
      }
      if(params['email']){
        this.updateJobMail(params['id'],params['email']);
      }
    });
  }

  public jobDetail :any = {};
  public showJobDetails :boolean = false;
  public showEmpLoginBtn :boolean = false;
  public showCandLoginBtn :boolean = false;
  public questionData :any = [];
  public jobmailArray :any = [];
  public candArray :any = [];
  public jobUrl :any = '';
  public showjob :boolean = true;
  public step1 :boolean = false;
  public step2 :boolean = false;
  public step3 :boolean = false;
  public answersData :any = [];
  public jobid :any = '';
  public showAvRat :boolean = false;
  public showNewRat :boolean = false;
  public userIDs :any = this.commonService.getToken("auid");
  public bxsliders :any ='';
  public showrated : boolean = false;
  public ratvalue :any = '';
  public qnsLength :any ='';
  public selectShareall :boolean = true;
  public ratestatus :boolean = true;
  public chiledRateStatus :boolean = true;
  public showRChart : boolean = false;
  public showPChart : boolean = false;
  public tagedataArray :any = [];
  public polarAreaChartLabels:string[] = [];
  public polarAreaChartData:number[] = [];
  public polarAreaLegend:boolean = true;
  public polarAreaChartType:string = 'polarArea';
  public title: string = 'My first AGM project';
  public latM: number;
  public lngM: number;
  public company_logo :any = '';


  // Radar
  public demoradarChartLabels:string[] = [];
 
  public demoradarChartData:any = [];
  public radarChartType:string = 'radar';
 
  ngOnInit() {

     $(window).scroll(function(){
        if ($(this).scrollTop() >= $('.jabri-app-parent .jd-action').offset().top) {
           $('h1.public-fixed').addClass('hfix');
           $('.pubview.fixed-title').addClass('fixed');
        } else {
           $('h1.public-fixed').removeClass('hfix');
           $('.pubview.fixed-title').removeClass('fixed');
        }
    });

    try{

    
    ////console.log(this.userIDs)  
    $('.cand-loader').show();
    var testArray = [
    {"rate": 1, "tag": "Values"},
    {"rate": 1.5, "tag": "Comm"},
    {"rate": 2, "tag": "Personality"},
    {"rate": 2.5, "tag": "Problemsolving"},
    {"rate": 3, "tag": "Values"}];
    
    var result=_.chain(testArray).groupBy("tag").map(function(v, i) {
      return {
        name: i,
        count: _.map(v, 'rate').length
      }
    }).value();
    }catch(error){

    }
  }

  ngAfterViewInit(){
    try{
    var _thiss = this;
    var $star_rating = $('.star-rating .fa');
    
    }catch(error){

    }
   
  }

  getAllTagList(jobId){
    try{
    this.commonService.callApi('get_all_tags_by_job', {"job_id":jobId}, 'post', false, true).then(success=>{
        if(success.status == 1){
          this.tagedataArray = success.data;
          //this.getAggregateRating(this.tagedataArray);
          var dataArray = [];
          var dataAlength = success.data.length;
          _.forEach(success.data,$.proxy(function(i, k){
              if(dataArray.indexOf(i.tags) < 0){
                 dataArray.push(i.tags)
              }

              //dataArray.push(i.tags)

              if(k === (dataAlength - 1)){
                ////console.log(dataArray)
                this.demoradarChartLabels = dataArray;
                this.polarAreaChartLabels = dataArray;
                this.getAggregateRating(this.tagedataArray, dataArray);
              }             

          },this));
          
        }else{
        }
    });
    }catch(error){

    }

  }

  getAggregateRating(tagary, newArr){
    try{
    var _thiss = this;
    this.commonService.callApi('get_aggregate_rating', {job_id: this.jobid, "tags":tagary}, 'post', false, true).then(success=>{
        if(success.status == 1){
          //console.log('getAggregateRating',success)
          // let list = _.filter(success.data, item => item.question_id === "5bec06c6e7dc431f304d1b1d");
          // //console.log("list",list)
          // _.forEach(daary, function(i,k){

          // })

          var tagLnt = tagary.length;
          var qusArray = success.data;
          var qusArrayLnt = success.data.length;
          var tag1Arry = [];

          for (var k = 0; k < qusArrayLnt; k++) {

            var ratlnt = qusArray[k].ratinglist.length;
            var karr = []

            for (var l = 0; l < tagLnt; l++) {
                var tagplus = 0;
                var tagName = '';
                var tagCount = 0;
                for (var i = 0; i < ratlnt; i++) {
                     if(qusArray[k].ratinglist[i].question_id === tagary[l]._id){
                       tagCount = tagCount + 1;
                       tagplus = tagplus + qusArray[k].ratinglist[i].rating;
                       tagName = tagary[l].tags;
                     }else{
                       tagplus = tagplus + 0;
                       tagName = tagary[l].tags;
                     }
                     if(i === (ratlnt - 1)){
                       tagplus = (tagplus === 0)? tagplus : (tagplus/tagCount);
                       karr.push({"rate":tagplus, "tag":tagName});
                       var karr =_.chain(karr).groupBy("tag").map(function(v, i) {
                          return {
                            tag: i,
                            rate: _.map(v, 'rate').reduce((a, b) => a + b, 0)/_.map(v, 'rate').length
                          }
                        }).value();
                      }
                }

                if(l === (tagLnt - 1)){
                    const ourCount = {};
                    const ourCountArry = [];
                    var tagCountF = 1;
                    var tagValF = 0;
                    ////console.log(karr)
                    karr.forEach((entry, v) => {
                      if(!ourCount[entry.tag]){
                        ourCount[entry.tag] = 0;
                      }
                      
                      ourCount[entry.tag] = ourCount[entry.tag] + entry.rate;

                    });
                    ////console.log(ourCount)
                    // var ourCountf = Object.values(ourCount);
                    // var result=_.chain(karr).groupBy("tag").map(function(v, i) {
                    //   return {
                    //     name: i,
                    //     age: _.map(v, 'rate').reduce((a, b) => a + b, 0)/_.map(v, 'rate').length
                    //   }
                    // }).value();
                    var ourCountf = Object.values(ourCount);
                    tag1Arry.push({data: ourCountf, label: qusArray[k].user[0].user_fullname});                   

                  } 
            }

            if(k === (qusArrayLnt - 1)){
                ////console.log(tag1Arry, newArr)
                this.demoradarChartData = tag1Arry;
                var karrLength = newArr.length;
                var ftagarrLength = tag1Arry.length;
                var newPieAry = [];
                var newPieTotal = 0;
                var newExactPaid = 0;
                for (var z = 0; z < karrLength; z++) {
                     newPieTotal = 0;
                     newExactPaid = 0;
                      for (var y = 0; y < ftagarrLength; y++) {
                        if(tag1Arry[y].data.length > 0 && tag1Arry[y].data[z] !== 0){
                          newExactPaid = newExactPaid + 1;
                          newPieTotal += tag1Arry[y].data[z];
                        }                        
                        
                        if(y === (ftagarrLength - 1)){
                          newPieTotal = (newPieTotal === 0)? newPieTotal : (newPieTotal/newExactPaid);
                          newPieAry.push(newPieTotal);
                        }
                      }
                      if(z === (karrLength - 1)){
                        this.showPChart = true;
                        ////console.log("newPieAry",newPieAry)
                        this.polarAreaChartData = newPieAry
                      }
                }
            }
            
          }          

        }else{
        }
    });
    }catch(error){

    }

  }


  ratingCode(_class){
    try{
    $(_class).each(function() {
        if (parseInt($(_class).siblings('input.rating-value').val()) >= parseInt($(this).data('rating'))) {
          $(this).removeClass('fa-star-o').addClass('fa-star active');
        } else {
          $(this).removeClass('fa-star').addClass('fa-star-o');
        }
    });
    }catch(error){

    }
  }

  getJobDetail(jobId?){
    try{
  	////console.log(jobId)
    
    //this.commonService.ChildToParent({"title":"load", "value" :true });
  	this.commonService.callApi('job_detail_allid', {"job_id":jobId, 'alldata':this.showJobDetails}, 'post', true).then(success=>{
        if(success.status == 1){
          try{
          //console.log(success)
          this.jobDetail = success.data;
          this.latM = success.data.company_Data.lat;
          this.lngM = success.data.company_Data.lng;
          this.company_logo = success.data.company_Data.company_logo;
          if(this.commonService.getToken('jobcname')){
            var compnayName = this.commonService.getToken('jobcname').split(' ').join('-').toLowerCase();
          }
          
          this.jobUrl = appSetting.frontUrl+compnayName+'-'+this.jobDetail.slug+'/job-'+jobId;
          var fbPreviewImg = appSetting.apiUrl+"public/job_thumbnails/thumb_"+jobId+".png";
          // this.meta.addTag({ name: 'og:title', property:"og:title", content: this.jobDetail.title });
          // this.meta.addTag({ name: 'og:image', property:"og:image", content: fbPreviewImg });
          // this.meta.addTag({ name: 'og:description', property:"og:description", content: this.jobDetail.description });
          // this.meta.addTag({ name: 'og:url', property:"og:url", content: this.jobUrl });
          // this.meta.addTag({ name: 'twitter:image', content: fbPreviewImg });
          // document.querySelector('meta[property="og:title"]').setAttribute("content", this.jobDetail.title);
          // document.querySelector('meta[property="og:image"]').setAttribute("content", fbPreviewImg);
          // document.querySelector('meta[property="og:description"]').setAttribute("content", this.jobDetail.description);
          // document.querySelector('meta[property="og:url"]').setAttribute("content", this.jobUrl);
          
          if(this.jobDetail.job_fdate && this.jobDetail.job_tdate){

            var fromDate = new Date(this.getDate(this.jobDetail.job_fdate));
            var expDate = new Date(this.getDate(this.jobDetail.job_tdate));
            // Get today's date
            var todaysDate = new Date();
            // call setHours to take the time out of the comparison
            if((expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0) || this.jobDetail.status === "inactive") && this.commonService.getToken('accessToken')) {
                //console.log("expireed 1111111");
                //this.showjob = false;
                this.step2 = false;
                this.step1 = false;
                this.step3 = true;
            }else if(expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0) || this.jobDetail.status === "inactive") {
              //console.log('2222222222')
                this.step2 = false;
                this.step1 = false;
                this.step3 = true;
            }else if(this.commonService.getToken('accessToken') && (fromDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0)) && (expDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0))){
              //console.log('333333')
                this.step2 = false;
                this.step1 = false;
                this.step3 = true;
            }else if(this.commonService.getToken('accessToken')){
               //console.log('44444444')
                this.step2 = false;
                this.step1 = true;
                this.step3 = false;
            }else if((fromDate.setHours(0,0,0,0) > todaysDate.setHours(0,0,0,0)) ||  (expDate.setHours(0,0,0,0) < todaysDate.setHours(0,0,0,0))){
              //console.log("expireed 55555555");
                //this.showjob = false;
                this.step2 = true;
                this.step1 = false;
                this.step3 = false;
            }else{
               //console.log('555555555')
                this.step2 = false;
                this.step1 = true;
                this.step3 = false;
            }
          }

          try{
            setTimeout($.proxy(function(){
              this.bxsliders =  $('.bxslider').bxSlider({
                  auto: false,
                  autoControls: false,
                  stopAutoOnClick: false,
                  pager: false,
                  infiniteLoop:false,
                  controls:true,
                  touchEnabled:false,
                  onSliderLoad:function(){
                  }
                });
              this.commonService.ChildToParent({"title":"load", "value" :false });
            },this),100);

          }catch(error){

          }

          

          this.questionData = success.questions;
          this.jobmailArray = success.jobmailData;
          this.qnsLength = success.questions.length;

          ////console.log(this.qnsLength)
          
          $('.cand-loader').hide();
          }catch(error){

          }
        }else{
          ////console.log(success)
          this.commonService.ChildToParent({"title":"load", "value" :false });
          this.commonService.popToast('error',success.message,1500)
        }
    });
    }catch(error){

    }
  }


  checkLogin(){
  	this.router.navigate(['/authentication/login-candidate']); 
  }

  getDate(data){
    var data = data.split('-');
    return data[1]+'/'+data[0]+'/'+data[2];
  }

  deleteQuestion(qid, idx){
    try{
    if(confirm("Are you sure you want to remove this question")){
      this.commonService.ChildToParent({"title":"load", "value" :true });
      this.commonService.callApi('delete_question', {"qid":qid}, 'post', false, true).then(success=>{
        ////console.log(success)
        if(success.status == 1){
          this.questionData.splice(idx, 1);
          this.commonService.ChildToParent({"title":"load", "value" :false });
          this.commonService.popToast('success',success.message,1500);
        }else{
          ////console.log(success)
          this.commonService.ChildToParent({"title":"load", "value" :false });
          this.commonService.popToast('error',success.message,1500)
        }

      });
    }
    }catch(error){

    }

  }

  updateAnswerRating(qid, ansid, rating, rater:any, qdx, adx, avraRat, canId){
    try{
    rater.push(this.commonService.getToken("auid").toString())

    var data = {
                "user_id": this.commonService.getToken("auid"),
                "user_name": this.commonService.getToken("fullname"),
                "rating": rating,
                "question_id":qid,
                "jobpost_id": this.jobid,
                "answer_id":ansid,
                "status":"active",
                "rater": rater,
                "avrage_rating": avraRat,
            };    
    var _class = '.fixrating'+qdx+adx+' .fa';
    this.ratingCode(_class);

    ////console.log(data)

    this.commonService.callApi('add_answer_rating', data, 'post', false, true).then(success=>{
        if(success.status == 1){
          ////console.log(success)
          //this.showAvRat = true;
          //window.location.reload();
          //this.questionData[qdx].answers[adx].rater.push(this.commonService.getToken("auid").toString())

         // $('.avrating'+qdx+adx+' input').val(rating); 
         // $('.avrating'+qdx+adx).show();
          // window.location.reload();

          //this.ratingavCode(qdx, adx);
          //this.getJobDetail2(this.jobid);

          if(success.data > 0){
            this.getUnratngData(canId, this.jobid, success.data);
          }          
          
        }else{

        }
    });
    }catch(error){

    }

  }

  calAvrage(qdx,adx){
    var _class = '.avrating'+qdx+adx+' .fa';
    this.ratingCode(_class);
  }

  calNewrage(qdx,adx){
    var _class= '.srating'+qdx+adx+' .fa';
    this.ratingCode(_class);
  }

  calCandAvrage(cand){
    var _class = '.candRating'+cand+' .fa'
    this.ratingCode(_class);
  }


  calGiveRate(data, qdx,adx){
    try{
    ////console.log(data, this.userIDs)
    if(data){
      ////console.log(data.indexOf(this.userIDs));
      if(data.indexOf(this.userIDs) > 0){
       $('.showNewRat'+qdx+adx).hide();
      }else{
        ////console.log('12121212','.showNewRat'+qdx+adx)
        $('.showNewRat'+qdx+adx).show();
      }
    }else{
       $('.showNewRat'+qdx+adx).show();
    }
    }catch(error){

    }

  }

  getUnratngData(canId, jobid, rat?){
    try{
    ////console.log("canId",canId)
    this.commonService.callApi('get_and_unrating', {"user_id":canId, "jobid":jobid}, 'post', false, true).then(success=>{
        if(success.status == 1){
          ////console.log(success)
          ////console.log(success.data.length)
          if(success.data.length === 0){
            $('.candRating'+canId+' input').val(rat); 
            var _class = '.candRating'+canId+' .fa'
            this.ratingCode(_class);
            $('.showCandRat'+canId).show();    
          }
        }else{

        }
    });
    }catch(error){

    }

  }

  copyCommand(jobUrl){
    try{
    var copyText = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
    }catch(error){

    }
  }

  updateJobMail(jobid, email){
    try{
      this.commonService.callApi('add_job_to_mail', {"jobpost_id":jobid, "email":email, "update":true}, 'post', true).then(success => {
        if (success.status == 1) {

        }else{

        }
      });
      }catch(error){

    }
  }

  openslide(event, mode, id, rate){
      try{
      event.preventDefault();
        var elem = $(event.target).parents('h2').next();
        elem.slideToggle();
        if(mode){
          $(event.target).attr('src',"/assets/images/circle-minius.svg");
        }else{
          $(event.target).attr('src',"/assets/images/circle-plus.svg");
        }
        ////console.log(rate)
        if(rate){
          this.ratestatus = !mode;
        }else{
          this.selectShareall = !mode;  
        }
        }catch(error){

    }
         
  }

  openChildslide(event, mode, id, candid){
    try{


         var elem = $(event.target);
         ////console.log( elem.parents('tr'))
          //elem.toggle('slow');
          if($(event.target).attr('src') === "/assets/images/circle-plus.svg"){
            ////console.log('111111');
            if($(event.target).attr('data-open')){
              ////console.log('000000')
              elem.parents('tr').next('tr').slideDown("slow");
            }else{
              this.getAllMemberRating(candid, function(result){

                var resLength = result.length;
                var tdth = "<th></th>";
                 var tbtd = '';

                _.forEach(result[0].raters,function(q){
                      
                       tdth += "<th>"+q.user_name+"</th>";
                });

                _.forEach(result,function(i,j){
                  var raterLength = i.raters.length;           
                  tbtd +=    '<tr><td>Question'+(j+1)+'</td>';
                  _.forEach(i.raters,function(k,p){
                      tbtd +=    '<td>'+k.rating+'</td>';
                      if(p === (raterLength - 1)){
                        tbtd +=    '</tr>';
                      
                         //////console.log(j, resLength)
                        if(j === (resLength - 1)){
                          var trTag = "<tr style='display:none;'>";
                          trTag += "<td colspan='5'><div>";
                          trTag +=  "<table>"
                          trTag += "<thead>";
                          trTag += "<tr>"+tdth+"</tr>";
                          trTag += "</thead>";
                          trTag += "<tbody>"+tbtd+"</tbody>";
                          trTag += "</table>";
                          trTag += "</div></td></tr>";
                          //////console.log(trTag)
                          elem.parents('tr').after(trTag);
                          elem.parents('tr').next('tr').slideDown("slow");
                        }
                      }
                  })

                });

              });
            }
            $(event.target).attr({'src':"/assets/images/circle-minius.svg","data-open":"opened"});

          }else{
            ////console.log('22222222');
            $(event.target).attr('src',"/assets/images/circle-plus.svg");
            elem.parents('tr').next('tr').slideUp('slow');
          }       
          }catch(error){

    } 

  }

  getAppliedCandidate(jobId){
    try{
    //////console.log(typeof sort,  sort, typeof byapplied, byapplied)
    
    this.commonService.callApi('get_applied_candidate_list', {"job_id":jobId, 'sort':false, 'byapplied':false}, 'post', false, true).then(success=>{
        if(success.status == 1){
          this.candArray = success.data;
          var arrLength = success.data.length;
          ////console.log("success",success)
          this.showRChart = true;
          _.forEach(success.data,$.proxy(function(i,k){
            var avrate = (i.candrate && i.candrate.length > 0) ? i.candrate[0].rating : 0;
            this.demoradarChartData.push({data: [avrate, 2, 3, 4], label: i.user[0].user_fullname})
             if(k === (arrLength-1)){
               ////console.log(this.demoradarChartData)
             }
          },this));
          // this.demoradarChartData = [
          //   {data: [20, 40, 15, 30, 12], label: 'Company A'},
          //   {data: [30, 40, 20, 35, 15], label: 'Company B'},
          //   {data: [30, 40, 20, 35, 15], label: 'Company 3'},
          // ];
        }else{
          
        }
    });
    }catch(error){

    }
  }

  getAllMemberRating(cand, fn){
    try{
    ////console.log(this.jobid)
    this.commonService.callApi('get_member_rating_by_question', {"jobpost_id":this.jobid, 'cand_id':cand}, 'post', false, true).then(success=>{
        if(success.status == 1){
          fn(success.data);
        }else{
          
        }
    });
    }catch(error){

    }
  }

  // events
  chartClicked(e:any):void {
    ////console.log(e);
  }
 
  chartHovered(e:any):void {
    ////console.log(e);
  }

  showPieCharts(){
    this.showPChart = true;
    this.showRChart = false;    
  }

  showReadarCharts(){
    this.showRChart = true;
    this.showPChart = false;
  }

}
