import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
declare var navigator : any
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';

@Component({
  selector: 'app-taly',
  templateUrl: './taly.component.html',
  styleUrls: ['./taly.component.css']
})
export class TalyComponent implements OnInit {

	 public loggedIn: boolean = true;
    public user: any = {}
    public formGroup: any;
    public showLoader: boolean = false;
    public contactForm : any = {}
  constructor(private meta: Meta, private titleService: Title, private commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {

        

    }

  ngOnInit() {
  	
    try{
      this.toggleVideo();
      $(document).ready(function() {
        $('.talynav .nav-link').click(function(e) {
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $('#talyForm').offset().top
          }, 1000);
        });
      });
    }catch(error){

    }
  }

  clickHeaderBack(kay){
        console.log(this.router.url)
        if(this.router.url != kay){
          this.commonService.ChildToSettingPopup({"title":"back", "value" :true });
          setTimeout($.proxy(function(){
            this.router.navigate([kay]);
          },this),500, false);
        }
      }

    goToHome() {
        $('html,body').animate({ scrollTop: 0 }, 500);
    }

  toggleVideo() {

        var videoTag = '<video autoplay loop muted playsinline #videoPlayer><source src="assets/video/interview-2.mp4" type="video/mp4" /></video>';
        //this.videoplayer.nativeElement.play();
        $('#videoele1').html(videoTag);
    }

    submitForm(f: NgForm){
      this.formGroup = f;
      try{
        if (f.valid) {

           f.value['form_type'] = 'contact_taly';
           console.log(f.value)
           this.showLoader = true;
           var fullname = this.getFullName(f.value.name, f.value.lname);
            f.value['name'] = fullname;
           this.commonService.callApi('add_market_form', f.value, 'post', true).then(success=>{
             console.log("success contact", success)
                if(success.status == 1){
                  if(this.formGroup){
                          this.formGroup.resetForm();        
                      }
                      this.showLoader = false;
                      this.commonService.ChildToSettingPopup({"title":"load", "message" : "contact us"});
                }else{
                  //console.log(success)
                  this.commonService.ChildToParent({"title":"load", "value" :false });
                  this.commonService.popToast('error',success.message,1500)
                }
           });
        }
      }catch(error){

      }
    			
    }

    getFullName(first, last) {
        if (first || last) {
            return first + ' ' + last;
        } else {
            return null;
        }
    }

}
