import { Component, PLATFORM_ID, Inject, Injectable, NgZone, APP_ID  } from '@angular/core';
import { LOCAL_STORAGE , WINDOW} from '@ng-toolkit/universal';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import {appSetting} from '../app.config';
import { Router, NavigationEnd } from "@angular/router";
declare var window;
import { NotifierService } from 'angular-notifier';
//declare var DetectRTC: any;


@Injectable()
export class CommonService{
	authorised :any= false;
  private readonly notifier: NotifierService;
  public permission: Permission;
	constructor(private router : Router,@Inject(WINDOW) private window: Window,@Inject(LOCAL_STORAGE) private localStorage: any,public _http:HttpClient, @Inject(PLATFORM_ID) platformId: Object, notifierService: NotifierService) {		
		this.platformId = platformId;
		this._apiUrl = appSetting.apiUrl;
    this.notifier = notifierService;
    this.permission = this.isSupported() ? 'default' : 'denied';
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {        
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        };
    });
	}

	public _apiUrl;
	public platformId;
	public dataArry:any;
  private previousUrl: string;
   private currentUrl: string;

  // Observable string sources

  private missionAnnouncedSetting = new Subject<object>();
  private missionConfirmedPopup = new Subject<object>();
  private missionAnnouncedSettingTitle = new Subject<object>();
  private missionAnnouncedSettingLoader = new Subject<object>();
  private missionAnnouncedSource = new Subject<object>();
  private missionConfirmedSource = new Subject<object>(); 

 
  // Observable string streams
  missionSetting$ = this.missionAnnouncedSetting.asObservable();
  missionPopup$ = this.missionConfirmedPopup.asObservable();
  missionSettingTitle$ = this.missionAnnouncedSettingTitle.asObservable();
  missionSettingLoder$ = this.missionAnnouncedSettingLoader.asObservable();
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();
 
  // Service message commands
  ChildToParent(mission: object) {
    //console.log('2222222222222');
    this.missionAnnouncedSource.next(mission);
  }
 
  ParentToChild(astronaut: object) {
    this.missionConfirmedSource.next(astronaut);
  }

  ChildToSettingParent(mission: object) {
    //console.log('2222222222222');
    this.missionAnnouncedSetting.next(mission);
  }

  ChildToSettingTitle(mission: object) {
    //console.log('2222222222222');
    this.missionAnnouncedSettingTitle.next(mission);
  }

  ChildToSettingLoader(mission: object) {
    //console.log('2222222222222');
    this.missionAnnouncedSettingLoader.next(mission);
  }

  ChildToSettingPopup(mission: object) {
    //console.log('2222222222222');
    this.missionConfirmedPopup.next(mission);
  }

  setToken(key,value){
    
    //var key:any = btoa(btoa(btoa(key)));
    if(isPlatformBrowser(this.platformId)){
      console.log(key,value)
      var encryptData = btoa(btoa(btoa(btoa(btoa(value)))));
      localStorage.setItem(key,encryptData);
    }
  }
  getToken(key){
    try{
      if(isPlatformBrowser(this.platformId)){
         var decryptData = null;
         //var key:any = btoa(btoa(btoa(key)));
         if(localStorage.getItem(key) && localStorage.getItem(key).length > 0){
            decryptData = atob(atob(atob(atob(atob(localStorage.getItem(key))))))
          }      
          return decryptData;
        }

    }catch(error){

    }
    
  }
  removeToken(key){
    //var key:any = btoa(btoa(btoa(key)));
    if(isPlatformBrowser(this.platformId)){
      localStorage.removeItem(key);
    }
  }
  clearToken(){
    if(isPlatformBrowser(this.platformId)){
      localStorage.clear()
    }
  }

  getPreviousUrl() {
    return this.previousUrl;
  }


    /*******************************************************************************************
	@PURPOSE      	: 	Call api.
	@Parameters 	: 	{
							url : <url of api>
							data : <data object (JSON)>
							method : String (get, post)
							isForm (Optional) : Boolean - to call api with form data
							isPublic (Optional) : Boolean - to call api without auth header
						}
	/*****************************************************************************************/
	callApi(url, data, method, isPublic?, isForm?): Promise<any> {
		let headers;
		if(isPublic){
			headers = new HttpHeaders({ 'content-Type': 'application/json'});
		}else{
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': this.getToken('accessToken')});
		}
		if(isForm){
			headers = new HttpHeaders({ 'Authorization': this.getToken('accessToken') });
		}
    ////console.log(this._apiUrl + 'api/' + url, data)

    ////console.log(DetectRTC.osName)

    // if(DetectRTC.osName === "Mac OS X"){
    //   this._apiUrl = "https://api.jabri.co/";
    // }
    //console.log(url)
		return new Promise((resolve, reject) => {
			if(method == 'post'){
				this._http.post(this._apiUrl + 'api/' + url, data, { headers })
				.subscribe(data => { resolve(data) }, error => { this.showServerError(error)})
			}else if(method == 'get'){
				// let params: { appid: 'id1234', cnt: '5' }
				this._http.get(this._apiUrl + 'api/' + url, { headers })
				.subscribe(data => { resolve(data) }, error => { this.showServerError(error)})
			}
		})
	}

  callLinkedInApi(): Promise<any> {
    let headers = new HttpHeaders({ 
      'content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST GET'
    });
    return new Promise((resolve, reject) => {
      this._http.get("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=81w6l1j3nd9veq&redirect_uri=https://api.jabri.co/api/auth_linkedin_callback&state=jabriautraliacompany&scope=r_liteprofile,r_emailaddress,w_member_social", { headers })
        .subscribe(data => { 
          resolve(data) 
        }, error => { 
            this.showServerError(error)
        })
    })
  }

  getJsonDataFronFile(url): Promise<any> {
    return new Promise((resolve, reject) => {
        this._http.get(url)
        .subscribe(data => { resolve(data) }, error => { this.showServerError(error)})
      
    })
  }

	getPeople(term: string = null, fieldName): Observable<any[]> {
		let data = {
         filter : {}
         }
         data.filter[fieldName] = term
        let items = []
        if(term.length > 2){
        	this.callApi('search', data, 'post').then(success=>{
	             success.data.firstName.forEach(a=>{
	               items.push({name : a})
	             })
	        })
        }else{
        	items = []
        }

        return of(items).pipe(delay(500));
    }

	/*****************************************************************************************/
	// @PURPOSE      	: 	To show server error
	/*****************************************************************************************/
	
	showServerError(e){
		// this.swal({
  //         position: 'center',
  //         type: 'error',
  //         text: 'Internal Server Error',
  //         showConfirmButton: false,
  //         timer: 1800,
  //         customClass : 'custom-toaster'
  //       })
	}

	popToast(type,title,time?){
    //console.log('1111111111')
    this.notifier.hideAll();
    this.notifier.notify( type, title );
      // this.toastr[type]('',title,
      // {
      //   timeOut: time,
      //   closeButton :true,
      //   enableHtml :true
      // });
  }

  notifyToast(type,title){
      // swal({
      //   position: 'top-end',
      //   type: type,
      //   text: title,
      //   showConfirmButton: false,
      //   timer: 1500
      // })
  }

  setData(data:any){
  	this.dataArry = '';
  	this.dataArry = data;
  }

  getData(){
  	return this.dataArry;
  }

  public isSupported(): boolean {
      return 'Notification' in window;
  }
  requestPermission(): void {
      let self = this;
      if ('Notification' in window) {
          Notification.requestPermission(function(status) {
              return self.permission = status;
          });
      }
  }
  create(title: string, options ? : PushNotification): any {
      let self = this;
      return new Observable(function(obs) {
          if (!('Notification' in window)) {
              //console.log('Notifications are not available in this environment');
              obs.complete();
          }
          if (self.permission !== 'granted') {
              //console.log("The user hasn't granted you permission to send push notifications");
              obs.complete();
          }
          let _notify = new Notification(title, options);
          _notify.onshow = function(e) {
              return obs.next({
                  notification: _notify,
                  event: e
              });
          };
          _notify.onclick = function(e) {
              return obs.next({
                  notification: _notify,
                  event: e
              });
          };
          _notify.onerror = function(e) {
              return obs.error({
                  notification: _notify,
                  event: e
              });
          };
          _notify.onclose = function() {
              return obs.complete();
          };
      });
  }
  generateNotification(source: Array < any > ): void {
      let self = this;
      source.forEach((item) => {
          let options = {
              body: item.alertContent,
              icon: "../../assets/images/jabri-logo-2.jpg"
          };
          let notify = self.create(item.title, options).subscribe();
      })
  }

}


export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
    body ? : string;
    icon ? : string;
    tag ? : string;
    data ? : any;
    renotify ? : boolean;
    silent ? : boolean;
    sound ? : string;
    noscreen ? : boolean;
    sticky ? : boolean;
    dir ? : 'auto' | 'ltr' | 'rtl';
    lang ? : string;
    vibrate ? : number[];
}


