import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgForm } from '@angular/forms';
import { CommonService } from '../common/common.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private commonService: CommonService, private router: Router) {
    $(document).ready(function() {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function() {
            window.history.pushState(null, "", window.location.href);
        };
    });
  }

  public datas :any = new Date();

  ngOnInit() {
  } 

  clickHeaderBack(kay){
    console.log(this.router.url)
    if(this.router.url != kay){
      this.commonService.ChildToSettingPopup({"title":"back", "value" :true });
      setTimeout($.proxy(function(){
        this.router.navigate([kay]);
      },this),500, false);
    }
  }

}
