import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CommonService } from '../common/common.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private commonService: CommonService,private router: Router) { }

    canActivate() {
        if (this.commonService.getToken('accessToken')) {
            // logged in so return true
            return true;
        }
        this.router.navigate(['/authentication/login']);
        return false;
    }
}

 