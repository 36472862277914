import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CommonService } from './common/common.service';
import { AuthGuard } from './common/auth.gaurd';
import { CheckAuthGuard } from './common/check.auth.gaurd';
import { LoginAuthGuard } from './common/login.auth.gaurd';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SocketService } from './common/socket.service'
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { ErrorComponent } from './error/error.component';

import { PricingComponent } from './pricing/pricing.component';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { SecurityComponent } from './security/security.component';
import { FooterComponent } from './footer/footer.component';
import { SubscribeComponent } from './subscribe/subscribe.component'; 
import { TalyComponent } from './taly/taly.component';
import { PublicViewComponent } from './public/public-view.component';
import { ViewJobComponent } from './view-job/view-job.component';
import { PublicCandidateSharePageComponent } from './public-candidate-share-page/public-candidate-share-page.component';
import { RecaptchaModule  } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VendorpanelComponent } from './vendorpanel/vendorpanel.component';
import { FreshfreshflowersComponent } from './freshfreshflowers/freshfreshflowers.component';
import { ViktrsComponent } from './viktrs/viktrs.component';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'middle',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 2500,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorComponent,
    PricingComponent,
    ContactUsComponent,
    RequestDemoComponent,
    SecurityComponent,
    FooterComponent,
    SubscribeComponent,
    PublicViewComponent,
    ViewJobComponent,
    TalyComponent,
    PublicCandidateSharePageComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    VendorpanelComponent,
    FreshfreshflowersComponent,
    ViktrsComponent
  ],
  imports:[
 	  CommonModule,
	  NgtUniversalModule,
	  BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled',}),
    FormsModule,
    HttpModule,
 	  HttpClientModule,
    NgxExtendedPdfViewerModule,     
    NotifierModule.withConfig(customNotifierOptions),
    RecaptchaModule  
  ],
  providers: [
    CommonService, 
    AuthGuard, 
    CheckAuthGuard, 
    LoginAuthGuard, 
    SocketService,
    //{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey }
  ],
  bootstrap: [
        AppComponent
    ],
})

export class AppModule { }
