import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgForm } from '@angular/forms';
import { CommonService } from '../common/common.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  public formGroups: any;
  public showLoader : boolean = false;
  public subsform :any = {};
  public showsucmsg : boolean = false;

  ngOnInit() {
  }

  submitSubForm(f: NgForm){
    this.formGroups = f;
    try{

      if (f.valid) {  
       this.showLoader = true;     
       this.commonService.callApi('add_market_subscriber', f.value, 'post', true).then(success=>{
         console.log("success request", success)
            if(success.status == 1){
              if(this.formGroups){
                      this.formGroups.resetForm();        
                  }
                  this.showLoader = false;
                  this.showsucmsg = true;

                  //setTimeout($.proxy(function(){
                  	//this.showsucmsg = false;
                  //},this),3000)     

            }else{
              //console.log(success)
              this.commonService.ChildToParent({"title":"load", "value" :false });
              this.commonService.popToast('error',success.message,1500)
            }
       });
    }
    }catch(error){

    }
  }

}
