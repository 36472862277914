import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

declare var window: any;
import { Subscription } from 'rxjs';
import { CommonService } from './common/common.service';
import { Router, NavigationEnd } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'jabri-front';
  public showThankModel : boolean = false;
  public msg : any = ''; 
  subscriptionPopup : Subscription;

  constructor(private commonService: CommonService, private _router: Router, private cdr: ChangeDetectorRef) {
    try{

          $(document).ready(function() {
              window.history.pushState(null, "", window.location.href);        
              window.onpopstate = function() {
                  window.history.pushState(null, "", window.location.href);
              };
              
          });

              //setTimeout(function(){
                //console.log('33333333')
                  window.onload = function() {
                        $('.jaben-animation').addClass('jabload');
                        $('.loader_main').fadeOut();
                        //self.showLoader = true;
                  };
              //},3000)
          
            this.subscriptionPopup = commonService.missionPopup$.subscribe(e => {
                try{
                  //alert('4454545');
                    this.openPopUp(e)
                }catch(error){

                }
            });
    }catch(error){
      console.log("error", error)
    }
    
  }

  ngOnInit() {
    try{
      //console.log('1111111111')
      $(window).on('popstate', $.proxy(function(event) {
            $('.back-main').removeClass('backdiv');
           //console.log("backurl",this.commonService.getPreviousUrl())
           var routeUrl = this.commonService.getPreviousUrl();
            $('body').addClass('backactive');
            $('body').addClass('bodybefore');
            $('.back-main').css('width',$(window).width());
            $('.back-main').css('height',$(window).height());
            $('.back-main').css('top','0');
            setTimeout($.proxy(function(){  
              $('.back-main').css('top','-100%');
              this._router.navigate([routeUrl]);
              
            },this), 500);
            setTimeout(function(){  
                $('.back-main').removeAttr('style');
            }, 1000);
            setTimeout(function(){  
                $('body').removeClass('backactive');
            }, 1500);
            setTimeout(function(){
              $('.jaben-animation').addClass('jabload');
                $('body').removeClass('bodybefore');
            }, 1600); 
      },this));
    }catch(error){
      console.log('error', error)
    }
    
  }

  openPopUp(e){
    try{
      //console.log('2222222222222222')
      if(e.title === "load"){
          this.showThankModel = true;
          this.msg = e.message;
          setTimeout($.proxy(function(){
            this.showThankModel = false;
          },this),4000)
          this.cdr.detectChanges()
      }else if(e.title === 'back'){
        this.showLoader()
      }
      
    }catch(error){

    }
  }

  showLoader(){
    //console.log('22222222222')
       $('.back-main').removeClass('backdiv');
       //console.log("backurl",this.commonService.getPreviousUrl())
       var routeUrl = this.commonService.getPreviousUrl();
        $('body').addClass('backactive');
        $('body').addClass('bodybefore');
        $('.back-main').css('width',$(window).width());
        $('.back-main').css('height',$(window).height());
        $('.back-main').css('top','0');
        setTimeout($.proxy(function(){  
          $('.back-main').css('top','-100%');
          this._router.navigate([routeUrl]);
          
        },this), 500);
        setTimeout(function(){  
            $('.back-main').removeAttr('style');
        }, 1000);
        setTimeout(function(){  
            $('body').removeClass('backactive');
        }, 1500);
        setTimeout(function(){
          $('.jaben-animation').addClass('jabload');
            $('body').removeClass('bodybefore');
        }, 1600);  

  }

  closeModelBox(){
    try{
      this.showThankModel = false;
    }catch(error){

    }
  }

}
