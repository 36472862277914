import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { NgForm, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { Meta } from '@angular/platform-browser';
import { SocketService } from '../common/socket.service';
import { appSetting } from '../app.config';
declare var $: any;
import * as _ from "lodash";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-public-candidate-share-page',
  templateUrl: './public-candidate-share-page.component.html',
  styleUrls: ['./public-candidate-share-page.component.css']
})
export class PublicCandidateSharePageComponent implements OnInit {

    //subscriptionCandPopup : Subscription;
    subscriptionForConnect : Subscription;
    subscriptionForChatData : Subscription;
    subscriptionForAppliedJOb : Subscription;

    constructor( private commonService: CommonService, private activatedRoute: ActivatedRoute, private router: Router, private socketService: SocketService) {

        var userRole = this.commonService.getToken('role');
        if (this.commonService.getToken('accessToken') && (userRole === "client_employer" || userRole === "reviewer")) {
          this.showLoginBtn = false;
        } else if (this.commonService.getToken('accessToken') && (userRole === "candidate")) {
            this.showLoginBtn = false;
        }

        this.activatedRoute.params.subscribe(params => {
            console.log(params);
            if (params['id'] && params['candid']) {
                this.showBxContainer = false;
                this.jobid = params['id'];
                //this.commonService.setToken('jobdid', params['id']);
                this.getAppliedCandidate(params['id'], params['candid'], false, false, params['check']);
                this.from_noty = true;
                //this.getQuestionList(params['candid'], "", params['check'])

            } else if (params['id']) {
                this.showBxContainer = false;
                //this.getJobDetail(params['id']);
                this.jobid = params['id'];
                //this.commonService.setToken('jobdid', params['id']);
                this.getAppliedCandidate(params['id'], '', false, false, '')
                //console.log('11111111111111')
            } else {
                //this.router.navigate(['/job-list']); 
                var compnayName = this.commonService.getToken('jobcname').split(' ').join('-').toLowerCase();
                this.router.navigate(['/home/' + compnayName + '/jobs']);
            }
        });

        $('.tab1act').addClass('current');
        
    }

    public jobDetail: any = {};
    public showLoginBtn: boolean = true;
    public from_noty: boolean = false;
    public questionData: any = [];
    public candList: any = [];
    public candListcandBackup :any = [];
    public questionList: any = [];
    public jobUrl: any = '';
    public showjob: boolean = true;
    public step1: boolean = false;
    public step2: boolean = false;
    public step3: boolean = false;
    public answersData: any = [];
    public jobid: any = '';
    public qusId: any = '';
    public showAvRat: boolean = false;
    public showNewRat: boolean = false;
    public userIDs: any = this.commonService.getToken("auid");
    public bxsliders: any = '';
    public bxsliders2: any = '';
    public showrated: boolean = false;
    public ratvalue: any = '';
    public qnsLength: any = '';
    public showBxslide: boolean = false;
    public candUserId: any = '';
    public answerData: any = '';
    public candData: any = {};
    public showCandProfile: boolean = false;
    public showRateText: any = '';
    public showmiddle: boolean = false;
    public showDetail: boolean = false;
    public chatmesg: any = {};
    public conversationID: any = {};
    public chatListing: any = [];
    public compnayId: any = this.commonService.getToken("company");
    public ratingData: any = [];
    public showurater: boolean = false;
    public jobAppliedId: any = '';
    public unreadCount: any = '';
    public showBxContainer: boolean = false;
    public jobDicision: any = '';
    public canRaterArry: any = [];
    public candataListArray: any = [];
    public filterstar: any;
    public filterapplied: any;
    public jobTitleName: any = '';
    private subscription1;
    private subscription2;
    private subscription3;
    public showRateCard1: boolean = false;
    public showRateCard2: boolean = false;
    public showShare :boolean = false;
    public sectedCandData: any = [];
    public selectShareall: boolean = true;
    public candCheck :any[] = [];
    public search :any = '';
    public seacrhcandlistlength :number = 0;
    public companyJobDetail :any = {};
    public companyJobDetailLogo :any = '';
    public items1: any[] = [
        { id: "0", name: 'All' },
        { id: "1", name: '1 Star' },
        { id: "2", name: '2 Star' },
        { id: "3", name: '3 Star' },
        { id: "4", name: '4 Star' },
        { id: "5", name: '5 Star' },
        { id: "6", name: 'Selected' },
        { id: "7", name: 'Not Selected' },
        { id: "8", name: 'Reviewed' },
        { id: "9", name: 'Not Reviewed' }
    ];

    public items2: any[] = [
        { id: "0", name: 'All' },
        { id: "1", name: 'Today applied' },
        { id: "2", name: 'This Week' },
        { id: "3", name: 'This Month' },
        { id: "4", name: 'This Year' }
    ];


    ngOnInit() {
        try {

            //console.log("userIDs", this.userIDs)
            var _thiss = this;
            $('.cand-loader').hide();

            this.filterstar = "0";
            this.filterapplied = "0";
            setTimeout(function() {
                $('.slide-toggle.notoggled').trigger('click');
            }, 3000);

            //this.socketHanldler();
            this.getAllMemberByCompany();
            this.chatmesg.dicision = 'Select Status';
            var data = {};
            data['user_id'] = this.userIDs;
            data['company_id'] = this.compnayId;
            data['job_id'] = this.jobid;
            
            setTimeout($.proxy(function(){
                var img = (this.commonService.getToken('jobclogo'))? this.commonService.getToken('jobclogo') : null;
                var jobtitle = (this.commonService.getToken('jobtitle'))? this.commonService.getToken('jobtitle') : null;
                $('.select-job-dd').empty().html('<img class="droplogo" src="'+img+'" width="30", height="30" />'+jobtitle+'<img class="dropicon" src="assets/images/jdropicon.svg"/>');
            },this),100);
            
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    ngAfterViewInit() {

        try {
            var fullname = this.commonService.getToken("fullname")
            var _thiss = this;

            var addratingcom = false;


            // $('body').on('mouseenter', '.star-rating .fa', function(e) {

            //     $(this).prevAll().addClass('fa-star active').removeClass('fa-star-o')
            //     $(this).nextAll().addClass('fa-star-o').removeClass('fa-star active')
            //     ////console.log($(this),$(this).hasClass('fa-star-o'))
            //     if ($(this).hasClass('active')) {
            //         ////console.log('11111111')
            //         ////console.log($(this))
            //         $(this).removeClass('active')
            //     } else {
            //         ////console.log('2222222222')
            //         $(this).addClass('active')
            //     }

            // });

            // $('body').on('mouseleave', '.star-rating', function(e) {
            //     ////console.log('11111111111111111111')
            //     if (!addratingcom) {
            //         $('.star-rating .fa').removeClass('fa-star active').addClass('fa-star-o');
            //     }
            //     var thisVal = $(this).find('.rating-value').val();
            //     $(this).find('.rating-value').val(thisVal);
            //     var asnId = $(this).attr("data-ansid");
            //     _thiss.calNewrage(asnId);
            //     //$('[data-toggle="tooltip"]').tooltip('hide')
            //     $('.tooltip[role="tooltip"]').tooltip('dispose');
            //     $('.tooltip[role="tooltip"]').remove();
            // })

            // $('body').on('mouseenter', '.star-rating', function(e) {
            //     $('[data-toggle="tooltip"]').tooltip();
            // });





            // $(document).on('click','.bxslider div', function(e){

            //   $(this).parent().prepend($(this));
            //   _thiss.bxsliders.reloadSlider({
            //             controls:true,
            //             pager: false,
            //             touchEnabled:false,
            //             maxSlides: 3,
            //             moveSlides: 1,
            //             hideControlOnEnd : true,
            //             slideWidth: 179,
            //             slideMargin: 14,
            //             infiniteLoop:false
            //         });

            // });



            var $star_rating = $('.star-rating .fa');
            // $('body').off('click').on('click', '.star-rating .fa', function(evt) {
            //     evt.preventDefault();
            //     addratingcom = true;
            //     var ratid = $(this).attr('data-rat_id');
            //     var actrat = $(this).data('actrat');
            //     var ansid = $(this).data('ansid');
            //     var qid = $(this).data('qid');
            //     var ratingAct = $(this).data('rating');
            //     var canId = $(this).data('cand');
            //     //////console.log('rat_id', actrat, ratid)
            //     var othereanswerData = _thiss.answerData.rater;
            //     ////console.log("_thiss.answerData",_thiss.answerData)
            //     var allRatingArry = _thiss.answerData.ratings;

            //     if (actrat === "inactive" && !ratid) {
            //         $(this).addClass('active').siblings('input.rating-value').val(ratingAct);
            //         var _class = '.srating' + ansid + ' .fa';
            //         _thiss.ratingCode(_class);

            //         //$('.srating'+ansid+' span').attr('data-actrat', 'active');

            //         $('.showrated' + ansid + ' input').val($(this).data('rating'));
            //         $('.showrated' + ansid).show();

            //         //////console.log(_thiss.answerData)  


            //         ////console.log(allRatingArry);
            //         allRatingArry.push({
            //             "user_id": _thiss.commonService.getToken("auid"),
            //             "user_name": _thiss.commonService.getToken("fullname"),
            //             "rating": ratingAct,
            //             "status": "active",
            //             "_id": Math.random().toString(36).substring(2)
            //         })


            //         var k = 0;
            //         var p: any = 0;
            //         //var Avhtml = "<select><option>Star Ratings</option>";



            //         var Avhtml = '<div class="dropdown">' +
            //             '<button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
            //             'Star Ratings' +
            //             '</button>' +
            //             '<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">';

            //         var avhtmlLength = allRatingArry.length;
            //         if (avhtmlLength > 0) {
            //             _.forEach(allRatingArry, function(i, j) {
            //                 var uname = (i.user_id === _thiss.commonService.getToken("auid")) ? 'You' : i.user_name;
            //                 Avhtml += '<a class="dropdown-item 111" href="javascript:void(0)">' + uname + ' - ' + i.rating + 'star</a>';

            //                 k = k + i.rating;
            //                 if (j === (avhtmlLength - 1)) {

            //                     p = (k / avhtmlLength);

            //                     //Avhtml += '<a class="dropdown-item 111" href="javascript:void(0)">Avrage - '+p+'star</a>';
            //                     Avhtml += "  </div></div>";
            //                     // ////console.log(Avhtml, '.showAvrate'+canId)
            //                     //$('.showAvrate'+ansid).show();

            //                     ////console.log(Avhtml)

            //                     $('.makestart' + ansid).html(Avhtml);

            //                     _thiss.updateAnswerRating(qid, ansid, ratingAct, othereanswerData, p, canId, ratid);

            //                 }
            //             });
            //         } else {
            //             //////console.log('ele+++++')
            //             Avhtml += '<a class="dropdown-item 222" href="javascript:void(0)"> You - ' + ratingAct + 'star</a>';
            //             Avhtml += " </div> </div>";
            //             //////console.log(Avhtml, '.showAvrate'+ansid)
            //             //$('.showAvrate'+ansid).show();
            //             $('.makestart' + ansid).html(Avhtml);
            //             _thiss.updateAnswerRating(qid, ansid, ratingAct, _thiss.answerData.rater, ratingAct, canId, ratid);
            //         }

            //     } else {
            //         var avhtmlLength = allRatingArry.length;
            //         ////console.log('232323232', ratingAct);
            //         //////console.log("ratingData", _thiss.ratingData)
            //         _thiss.ratingData[0]['rating'] = ratingAct;
            //         $(this).addClass('active').siblings('input.rating-value').val(ratingAct);
            //         var _class = '.srating' + ansid + ' .fa';
            //         _thiss.ratingCode(_class);
            //         var k2 = 0;
            //         var p2: any = 0;
            //         if (avhtmlLength > 0) {
            //             _.forEach(allRatingArry, function(i, j) {
            //                 k2 = k2 + i.rating;
            //                 if (j === (avhtmlLength - 1)) {
            //                     p2 = (k2 / avhtmlLength);
            //                     _thiss.updateAnswerRating(qid, ansid, ratingAct, othereanswerData, p2, canId, ratid);

            //                 }
            //             });
            //         }
            //         // _thiss.updateAnswerRating(qid, ansid, ratingAct , othereanswerData, 0, canId, ratid);
            //     }

            // });


            // $(document).on('mouseover', '.chat-desc', function() {
            //     if ($(this).attr('data-status') === "unread") {
            //         _thiss.updateChatReadStatus($(this).attr('data-chat_id'), true)
            //         $(this).attr('data-status', 'read')
            //     }

            // });

        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    onMouseLeaveFunction(event) {
        try {
            var addratingcom = false;
            //console.log('11111111111111111111')
            if (!addratingcom) {
                $('.star-rating .fa').removeClass('fa-star active').addClass('fa-star-o');
            }
            var thisVal = $(event.target).find('.rating-value').val();
            $(event.target).find('.rating-value').val(thisVal);
            var asnId = $(event.target).attr("data-ansid");
            this.calNewrage(asnId);
            //$('[data-toggle="tooltip"]').tooltip('hide')
            $('.tooltip[role="tooltip"]').tooltip('dispose');
            $('.tooltip[role="tooltip"]').remove();
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    updateChatReadStatus(chat_id, mode) {
        try {
            //console.log('1212121212', chat_id, mode, this.unreadCount)
            if (mode && this.unreadCount > 0) {
                this.unreadCount = (this.unreadCount - 1);
            }

            this.commonService.callApi('update_chat_read_status', { "chat_id": chat_id }, 'post', false, true).then(success => {
                if (success.status == 1) {
                    ////console.log("dicision",success)
                    //this.conversationID = success.data._id;

                } else {

                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    ratingCode(_class) {
        try {
            $(_class).each(function() {
                if (parseInt($(_class).siblings('input.rating-value').val()) >= parseInt($(this).data('rating'))) {
                    $(this).removeClass('fa-star-o').addClass('fa-star active');
                } else {
                    $(this).removeClass('fa-star active').addClass('fa-star-o');
                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    socketHanldler() {

    }

    getAppliedCandidate(jobId, candid, sort, byapplied, check ? ) {
        try {
            if ((sort === false) || (byapplied === false)) {
                this.commonService.ChildToParent({ "title": "load", "value": true });
            }
            console.log("jobId", jobId)

            this.commonService.callApi('get_shared_candidate_list', { "job_id": jobId, 'sort': sort, 'byapplied': byapplied }, 'post', true).then(success => {
                if (success.status == 1) {
                    ////console.log('ddddddddd',success)
                    $('.center-m-box').show();
                    this.showBxContainer = true;
                    this.showDetail = true;


                    this.candList = success.data;
                    this.candListcandBackup = success.data;
                    this.companyJobDetail = success.JobpostData;
                    this.companyJobDetailLogo = success.JobpostData.company_id.company_logo
                    $('.cand-loader').hide();
                    $('#videoele').html('<img src="/assets/images/dummy-video.svg" alt="" style="margin-top: 45px" class="img-fluid">');
                    try {
                        setTimeout($.proxy(function() {

                            //sort = parseInt(sort);
                            //byapplied = parseInt(byapplied);

                            // if ((sort === false) || (byapplied === false)) {
                            //     //console.log('111111')
                            //     this.bxsliders = $('.bxslider').bxSlider();
                            //     this.bxsliders2 = $('.bxslider2').bxSlider();
                            // }

                            if (this.from_noty) {
                                console.log('1111111111111');
                                $('.cand-detail-list-item').removeClass('cand-active');
                                $('.cndact' + candid).addClass('cand-active');
                                $('.cndact' + candid).trigger('click');
                                ////console.log( $('.cndact'+candid),  $('.cndact'+candid)[0])
                                if ($('.cndact' + candid)[0]) {
                                    $('.cndact' + candid)[0].scrollIntoView();
                                }

                                //this.getQuestionList(candid, " ", check)
                            } else {
                                console.log('22222222222');
                                //$('.cndact' + this.candList[0].user[0]._id).trigger('click')
                                this.getQuestionList(this.candList[0].user[0]._id, this.candList[0].applied_id, this.candList[0].appliedjobs[0].decision, this.candList[0].jobpost_id, 0)
                            }

                            // _.forEach(this.candList,$.proxy(function(item){
                            //   this.calCandAvrage(item.user[0]._id);
                            // },this))

                            this.commonService.ChildToParent({ "title": "load", "value": false });

                        }, this), 10);

                    } catch (error) {
                        //console.log("error", error)
                    }


                } else {
                    $('.cand-loader').hide();
                    this.candList = [];
                    this.questionList = [];
                    this.answerData = [];
                    //this.showBxContainer = false;
                    $('.center-m-box').hide();
                    this.showCandProfile = false;

                    this.commonService.ChildToParent({ "title": "load", "value": false });
                    if ((sort === false) || (byapplied === false)) {
                        this.commonService.popToast('error', "No applicant applied on this job", 1500);
                        setTimeout($.proxy(function() {
                            //this.router.navigate(['/job-list']);
                            var compnayName = this.commonService.getToken('jobcname').split(' ').join('-').toLowerCase();
                            //this.router.navigate(['/home/' + compnayName + '/jobs']);
                        }, this), 1500, false);

                    }
                    //this.commonService.popToast('error',success.message,1500);          
                }
            });
        } catch (error) {
        	console.log('error', error)
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    getQuestionList(candid, appliedId, decision, jobpost_id, index) {
        console.log(candid, appliedId, decision, jobpost_id, index)
        try {

            var _thiss = this;

            $('.blinkIcn' + appliedId).html('')
            this.candUserId = candid;
            //console.log(this.candUserId)
            this.jobAppliedId = appliedId;

            try{
                this.bxsliders.destroySlider();
                this.bxsliders2.destroySlider();
            }catch(error){

            }


            this.showBxslide = false;
            this.chatmesg.dicision = decision || "Select Status";
            this.getCandidateProfile(index);
            this.chatListing = [];
            this.questionList = [];
            ////console.log('12121212')   

            $('.apnd_chat').html('');
            this.getConversationId(candid);
            $('.jabri-main-content').scrollTop(0);
            $('.answ-middle').show();
            //this.commonService.ChildToParent({"title":"load", "value" :true });
            this.commonService.callApi('get_question_by_job', { "job_id": jobpost_id }, 'post', true).then(success => {
                if (success.status == 1) {
                    //console.log(success)
                    this.showRateCard2 = false;
                    this.showRateCard1 = false;
                    this.questionList = success.data;
                     this.answerData = [];
                    //console.log("thisval===" + success.data.length)
                    var qstLength = (success.data.length - 1) * 203;
                    this.jobTitleName = (success.data[0].jobposts) ? success.data[0].jobposts.title : '';
                    this.showBxslide = true;


                    setTimeout(function() {
                        ////console.log(_thiss.bxsliders)
                        try {
                            _thiss.bxsliders = $('.bxslider').bxSlider({
                                controls: false,
                                pager: false,
                                touchEnabled: false,
                                maxSlides: 3,
                                moveSlides: 1,
                                hideControlOnEnd: true,
                                slideWidth: 189,
                                slideMargin: 14,
                                infiniteLoop: true,
                                onSliderLoad: (function() {
                                     $("#bottomQstSlider").css("visibility", "visible");
                                    $('.bxslider').find('.quest-detail[data-slide-index="0"]').addClass("bx-active");
                                    var x = $('.cand-center-inner').width();
                                    $('.cand-quest-main').css('width', x - 105);
                                    var maxHeight = -1;
                                    $('.quest-slider .quest-detail').each(function() {
                                        if ($(this).height() > maxHeight) {
                                            maxHeight = $(this).height();
                                        }
                                    });
                                    $('.quest-slider .quest-detail').height(maxHeight);

                                    $('#bxscroll').on('input', function() {
                                        var x = $(this).val();
                                        $('.bxslider').css({ 'transform': 'translate3d(-' + x + 'px , 0px, 0px)' });
                                    });

                                    $('.quest-detail').on('click', function() {
                                        $('.quest-detail').removeClass('bx-active');
                                        $(this).addClass('bx-active');
                                    });

                                })
                            });

                            _thiss.bxsliders2 = $('.bxslider2').bxSlider({
                                //slideMargin: 100,
                                maxSlides: 1,
                                controls: true,
                                pager: false,
                                touchEnabled: false,
                                infiniteLoop: false,
                                hideControlOnEnd: true,
                                onSliderLoad: (function() {
                                   $("#topQstSlider").css("visibility", "visible"); 
                                }),
                                onSlideNext: (function($slideElm, oldIndex, newIndex) {
                                    _thiss.bxsliders.goToSlide(newIndex);
                                    $('.bxslider').find('.quest-detail').removeClass('bx-active');
                                    $('.bxslider').find('.quest-detail[data-slide-index="' + newIndex + '"]').addClass("bx-active")
                                    _thiss.getAnswerByJObQs(_thiss.questionList[newIndex]._id, newIndex, jobpost_id, 'bottom');
                                    var x = $('.cand-center-inner').width();
                                    $('.cand-quest-main').css('width', x - 105);
                                    var scrlngt = newIndex * 203;
                                    $('#bxscroll').val(scrlngt);
                                }),
                                onSlidePrev: (function($slideElm, oldIndex, newIndex) {
                                    _thiss.bxsliders.goToSlide(newIndex);
                                    $('.bxslider').find('.quest-detail').removeClass('bx-active');
                                    $('.bxslider').find('.quest-detail[data-slide-index="' + newIndex + '"]').addClass("bx-active")
                                    _thiss.getAnswerByJObQs(_thiss.questionList[newIndex]._id, newIndex, jobpost_id, 'bottom');
                                    var x = $('.cand-center-inner').width();
                                    $('.cand-quest-main').css('width', x - 105);
                                    var scrlngt = newIndex * 203;
                                    $('#bxscroll').val(scrlngt);
                                })
                            });

                            //$('.candRating'+candid+' .profile-avg').text();

                        } catch (error) {
                            ////console.log(error)
                        }


                        _thiss.getAnswerByJObQs(_thiss.questionList[0]._id, 0, jobpost_id, 'up');
                        $('.quest-detail:first').addClass('bx-active');
                        _thiss.commonService.ChildToParent({ "title": "load", "value": false });
                        var x = $('.cand-center-inner').width();
                        $('.cand-quest-main').css('width', x - 105);

                        $('#videoele').html('<img src="/assets/images/dummy-video.svg" alt="" style="margin-top: 45px" class="img-fluid">');
                        $('#bxscroll').attr("max", qstLength);

                    });

                    //history.pushState(null, null, 'https://192.168.86.75:4100/job-id/detail/'+this.jobid);


                } else {
                    //////console.log(success)
                    this.commonService.ChildToParent({ "title": "load", "value": false });
                    this.commonService.popToast('error', success.message, 1500)
                }
            });
        } catch (error) {
        	console.log("error",error)
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    getAnswerByJObQs(qid, index, jobpost_id, type) {
        try {
            //console.log(index)
            var scrlngt = index * 203;
            $('#bxscroll').val(scrlngt);
            this.showRateCard2 = false;
            this.showRateCard1 = false;
            $('.tooltip[role="tooltip"]').remove();
            $('.showrated').hide().html();
            $('.showAvrate').hide();
            this.bxsliders.goToSlide(index);
            this.answerData = [];

            $('.makestart').html('').hide();
            var getcanratval = this.showRateText || $('.candRating' + this.candUserId + ' input').val();
            ////console.log('getcanratval', $('.candRating'+this.candUserId+' input').val(),'*****' ,this.showRateText)
            this.showurater = false;

            this.qusId = qid;
            //////console.log({"job_id":this.jobid,"question_id":qid, "cand_id":this.candUserId})
            //this.commonService.ChildToParent({"title":"load", "value" :true });
            $('.answ-middle').show();
            $('#videoele').html('<img src="/assets/images/dummy-video.svg" alt="" style="margin-top: 45px" class="img-fluid">');
            var filter = { "jobpost_id": jobpost_id, "question_id": qid, "cand_id": this.candUserId };
            this.commonService.callApi('get_answer_by_jobQs', filter, 'post', true).then(success => {
                //console.log("success",success)
                if (success.status === 1) {
                    ////console.log("success",success)
                    this.showmiddle = true;
                    var ansid = success.data[0]._id;
                    this.answerData = success.data[0];


                    //console.log(this.answerData.rater.indexOf(this.userIDs))
                    if (this.answerData.rater.indexOf(this.userIDs) < 0) {
                        this.showRateCard2 = true;
                        this.showRateCard1 = false;
                    } else {
                        this.showRateCard2 = false
                    }

                    _.forEach(this.answerData.ratings, $.proxy(function(i) {
                        //console.log("00000", i.user_id, this.userIDs)
                        if (i.user_id == this.userIDs) {
                            //console.log('1111111')
                            this.showRateCard1 = true;
                            this.showRateCard2 = false;
                            $('.srating' + ansid + ' span').attr("data-rat_id", i._id);
                        }
                    }, this));

                    if (this.answerData.rater.indexOf(this.userIDs) >= 0 && success.unrating.length === 0) {
                        //console.log('12121212121', success.unrating)
                        $('showCandAvrate1' + this.candUserId).show();
                        $('showCandAvrate2' + this.candUserId).hide();
                        ////console.log('.candRating'+this.candUserId+' input', getcanratval)
                        $('.candRating' + this.candUserId + ' input').val(getcanratval);
                        this.showRateText = getcanratval;

                    } else {
                        ////console.log('showCandAvrate2'+this.candUserId)
                        $('showCandAvrate1' + this.candUserId).hide();
                        $('showCandAvrate2' + this.candUserId).show();
                    }

                    if (success.unrating.length === 0) {
                        //////console.log('111111111')
                        this.showurater = true;
                    }

                    this.ratingData = success.data[0].ratings;

                    try {
                        if (type === "bottom") {
                            this.bxsliders2.goToSlide(index);
                        } else {
                            this.bxsliders.goToSlide(index);
                        }


                        //$('#videoele').html('<video controls crossorigin="anonymous" height="350" width="450"><source src="'+success.data.mp4_video_file+'" type="video/mp4"></video>');

                        var comRat = '';

                        var allRatingArry = this.answerData.ratings;
                        var avhtmlLength = this.answerData.ratings.length;

                        var k = 0;
                        var p: any = 0;
                        var Avhtml = "<select><option>Star Ratings</option>";
                        //////console.log(avhtmlLength)

                        if (avhtmlLength > 0) {
                            _.forEach(allRatingArry, $.proxy(function(i, j) {
                                Avhtml += '<option>' + i.user_name + ' - ' + i.rating + ' star</option>';
                                k = k + i.rating;
                                if (j === (avhtmlLength - 1)) {
                                    Avhtml += "</select>";
                                    //////console.log('.showAvrate'+this.candUserId)
                                    //$('.showAvrate'+this.candUserId).html(Avhtml);
                                    p = (k / avhtmlLength);
                                }
                            }, this));
                        }


                        setTimeout($.proxy(function() {
                            // if(usrRateShow){
                            //    $('.candUserRating'+this.candUserId).show();
                            //  }
                            $('#videoele').html('<video controls controlsList="nodownload noremoteplayback" disablePictureInPicture crossorigin="anonymous" height="350" width="450"><source src="' + this.answerData.cdn_file + '" type="video/mp4"></video>');
                            $('[data-toggle="tooltip"]').tooltip();

                            this.calNewrage(ansid);
                            $('.star-rating .fa').trigger('mouseenter');
                            $('.star-rating .fa').trigger('mouseleave');
                        }, this), 100, false);

                    } catch (error) {
                        ////console.log(error)
                    }


                    this.commonService.ChildToParent({ "title": "load", "value": false });
                    $('.answ-middle').hide();

                } else {
                    try{
                        //console.log('ssssss', this.answerData)
                        $('#videoele').html('<img src="/assets/images/dummy-video.svg" alt="" style="margin-top: 45px" class="img-fluid">');
                        this.commonService.ChildToParent({ "title": "load", "value": false });
                        this.commonService.popToast('error', success.message, 1500);
                        this.showmiddle = false;
                        $('.answ-middle').hide();
                        this.answerData['ratings'] = [];
                        this.answerData['rater'] = [];
                        $('.showAvrate').hide();
                        this.bxsliders2.goToSlide(index);

                    }catch(error){

                    }
                }
            });
        } catch (error) {
        	console.log("error",error)
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    getJobDetail(jobId ? ) {
        try {
            //////console.log(jobId)
            this.jobid = jobId;
            this.commonService.callApi('job_detail_newllid', { "job_id": jobId }, 'post', true).then(success => {
                if (success.status == 1) {
                    //////console.log(success)
                    this.jobDetail = success.data;

                    var fromDate = new Date(this.getDate(this.jobDetail.job_fdate));
                    var expDate = new Date(this.getDate(this.jobDetail.job_tdate));
                    // Get today's date
                    var todaysDate = new Date();
                    // call setHours to take the time out of the comparison
                    if ((expDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0) || this.jobDetail.status === "inactive") && this.commonService.getToken('accessToken')) {
                        ////console.log("expireed 1111111");
                        //this.showjob = false;
                        this.step2 = false;
                        this.step1 = false;
                        this.step3 = true;
                    } else if (expDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0) || this.jobDetail.status === "inactive") {
                        ////console.log('2222222222')
                        this.step2 = true;
                        this.step1 = false;
                        this.step3 = false;
                    } else if (this.commonService.getToken('accessToken') && (fromDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0)) && (expDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0))) {
                        ////console.log('333333')
                        this.step2 = false;
                        this.step1 = false;
                        this.step3 = true;
                    } else if (this.commonService.getToken('accessToken')) {
                        ////console.log('44444444')
                        this.step2 = false;
                        this.step1 = true;
                        this.step3 = false;
                    } else if ((fromDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0)) || (expDate.setHours(0, 0, 0, 0) < todaysDate.setHours(0, 0, 0, 0))) {
                        ////console.log("expireed 55555555");
                        //this.showjob = false;
                        this.step2 = true;
                        this.step1 = false;
                        this.step3 = false;
                    } else {
                        ////console.log('555555555')
                        this.step2 = false;
                        this.step1 = true;
                        this.step3 = false;
                    }

                    setTimeout($.proxy(function() {
                        this.bxsliders = $('.bxslider').bxSlider({
                            auto: false,
                            autoControls: false,
                            stopAutoOnClick: false,
                            pager: false,
                            infiniteLoop: false,
                            controls: true,
                            touchEnabled: false,
                            onSliderLoad: function() {}
                        });

                    }, this), 100);

                    this.questionData = success.questions;
                    this.qnsLength = success.questions.length;

                    //////console.log(this.qnsLength)
                    this.jobUrl = appSetting.frontUrl + 'job/job-id/detail/' + jobId;


                } else {
                    //////console.log(success)
                    this.commonService.ChildToParent({ "title": "load", "value": false });
                    this.commonService.popToast('error', success.message, 1500)
                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    checkLogin() {
        this.router.navigate(['/authentication/signup-candidate']);
    }

    getDate(data) {
        var data = data.split('-');
        return data[1] + '/' + data[0] + '/' + data[2];
    }

    getCurrntWeekMonthYear(type) {
        try {
            var today = new Date();
            var dateArray = [];
            if (type === '1') {
                dateArray.push(today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + (today.getDate())).slice(-2));
                return dateArray;
            } else if (type === '2') {
                var first = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday: any = new Date(today.setDate(first)).toUTCString();
                var lastday: any = new Date(today.setDate(last)).toUTCString();

                firstday = new Date(firstday);
                lastday = new Date(lastday);

                dateArray.push(firstday.getFullYear() + '-' + ("0" + (firstday.getMonth() + 1)).slice(-2) + '-' + ("0" + (firstday.getDate())).slice(-2));
                dateArray.push(lastday.getFullYear() + '-' + ("0" + (lastday.getMonth() + 1)).slice(-2) + '-' + ("0" + (lastday.getDate())).slice(-2));
                return dateArray

            } else if (type === '3') {
                dateArray.push(today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("01"));
                dateArray.push(today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("31"));
                return dateArray

            } else if (type === '4') {
                dateArray.push(today.getFullYear() + '-' + ("01") + '-' + ("01"));
                dateArray.push(today.getFullYear() + '-' + ("12") + '-' + ("31"));
                return dateArray
            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    deleteQuestion(qid, idx) {
        try {
            if (confirm("Are you sure you want to remove this question")) {
                this.commonService.ChildToParent({ "title": "load", "value": true });
                this.commonService.callApi('delete_question', { "qid": qid }, 'post', false, true).then(success => {
                    //////console.log(success)
                    if (success.status == 1) {
                        this.questionData.splice(idx, 1);
                        this.commonService.ChildToParent({ "title": "load", "value": false });
                        this.commonService.popToast('success', success.message, 1500);
                    } else {
                        //////console.log(success)
                        this.commonService.ChildToParent({ "title": "load", "value": false });
                        this.commonService.popToast('error', success.message, 1500)
                    }

                });
            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    updateAnswerRating(qid, ansid, rating, rater: any, avrat, canId, ratid) {
        ////console.log(qid, ansid, rating, rater, avrat, canId, ratid)
        try {

            var data = {
                "user_id": this.commonService.getToken("auid"),
                "user_name": this.commonService.getToken("fullname"),
                "cand_id": canId,
                "rating": rating,
                "question_id": qid,
                "jobpost_id": this.jobid,
                "answer_id": ansid,
                "status": "active",
                "avrage_rating": avrat,
                "rat_id": ratid
            };


            if (!ratid) {
                rater.push(this.commonService.getToken("auid").toString())
                data['rater'] = rater;
            }


            //////console.log(data);

            // var _class = '.fixrating'+ansid+' .fa';
            // this.ratingCode(_class);

            ////console.log("data-------", data)


            this.commonService.callApi('add_answer_rating', data, 'post', false, true).then(success => {
                if (success.status == 1) {
                    ////console.log("new_rating",success)



                    if (!ratid) {
                        $('.srating' + ansid + ' span').attr('data-rat_id', success.new_rating._id)
                    }

                    if (success.data > 0) {
                        this.getUnratngData(canId, this.jobid, ansid, success.data);
                    }

                } else {

                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    calAvrage(qdx, adx) {
        var _class = '.avrating' + qdx + adx + ' .fa';
        this.ratingCode(_class);
    }

    calNewrage(id) {
        var _class = '.srating' + id + ' .fa';
        //console.log(_class)
        this.ratingCode(_class);
    }

    calCandAvrage(cand) {
        var _class = '.candRating' + cand + ' .fa';
        this.ratingCode(_class);
    }


    calGiveRate(data, qdx, adx) {
        ////console.log(data, this.userIDs)
        if (data) {
            ////console.log(data.indexOf(this.userIDs));
            if (data.indexOf(this.userIDs) > 0) {
                $('.showNewRat' + qdx + adx).hide();
            } else {
                ////console.log('12121212','.showNewRat'+qdx+adx)
                $('.showNewRat' + qdx + adx).show();
            }
        } else {
            $('.showNewRat' + qdx + adx).show();
        }

    }

    getUnratngData(canId, jobid, ansid, rat ? ) {
        // ////console.log("canId",canId)
        // ////console.log("rat1111")
        try {
            this.commonService.callApi('get_cand_unrating', { "cand_id": canId, "jobpost_id": jobid, "employer_id": this.userIDs }, 'post', false, true).then(success => {
                if (success.status == 1) {
                    // ////console.log(success)
                    ////console.log(success.data.length)
                    if (success.data.length === 0) {

                        //this.showurater = true;
                        $('.makestart' + ansid).show();

                        var tofixed = rat.toFixed(1);
                        //console.log("tofixed", rat, tofixed)
                        $('.candRating' + canId + ' input').val(tofixed);
                        $('.candRating' + canId).find('.profile-avg').html(tofixed);
                        var _class = '.candRating' + canId + ' .fa'
                        this.ratingCode(_class);
                        $('.showCandRat' + canId).show();
                        $('.candUserRating' + canId).show();

                        this.addCandAvrat(tofixed);
                        this.showRateText = tofixed;



                        var findIndex = _.findIndex(this.candList, { 'user_id': canId });
                        //console.log("findIndex", findIndex)
                        if (findIndex >= 0) {
                            this.candList[findIndex].candrate = [];
                            this.candList[findIndex].candrate.push({
                                "rating": rat,
                                "rater": [this.commonService.getToken('auid')]
                            })
                            // this.candList[findIndex].candrate[0]['rating'] = rat
                            // this.candList[findIndex].candrate[0]['rater'] = [];
                            // this.candList[findIndex].candrate[0]['rater'].push(this.commonService.getToken('auid'));
                        }



                        var titleS = "Review completed By " + this.commonService.getToken('fullname');
                        this.sendSockeNoty('reviewComplete:employer', jobid, titleS, " ", this.jobAppliedId, this.candUserId, '', 'Review_Completed');
                    }

                } else {

                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    addCandAvrat(rating) {
        try {
            //console.log("this.canRaterArry", this.canRaterArry)
            if (this.canRaterArry.indexOf(this.commonService.getToken("auid").toString()) < 0) {
                this.canRaterArry.push(this.commonService.getToken("auid").toString())
            }
            var dataObj = {
                "jobpost_id": this.jobid,
                "rating": rating,
                "candid": this.candUserId
            }


            dataObj['rater'] = this.canRaterArry;

            ////console.log(dataObj)

            this.commonService.callApi('add_cand_rating', dataObj, 'post', false, true).then(success => {
                if (success.status == 1) {
                    //////console.log(success)
                    //this.conversationID = success.data._id;

                } else {

                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    getCandidateProfile(index) {
        try {
            //console.log("index", '.candRating-' + this.candList[index].user_id)

            this.showCandProfile = true;
            var candataList = this.candList;
            ////console.log("candData",this.candList[index], index)
            this.candData = candataList[index].user[0];

            this.candataListArray = candataList[index].candrate || [];
            this.showRateText = (candataList[index].candrate.length > 0) ? candataList[index].candrate[0].rating.toFixed(1) : '0.0';
            this.canRaterArry = (candataList[index].candrate.length > 0) ? candataList[index].candrate[0].rater : [];
            ////console.log('.candRating'+this.candList[index].user_id+' .profile-avg')

            setTimeout($.proxy(function() {
                $('.starav-rating .profile-avg').text('0.0');
                $('.cand_rating-' + this.candList[index].user_id + ' .profile-avg').text(this.showRateText.toString());

                this.calCandAvrage(this.candUserId)

            }, this), 100, false);

            ////console.log("showRateText",this.showRateText)
            ////console.log(this.canRaterArry)
            // this.commonService.callApi('userProfile', {"uid":id}, 'post', false, true).then(success=>{
            //     if(success.status == 1){
            //      // ////console.log(success)
            //       this.showCandProfile = true;
            //       this.candData = success.data;
            //       this.showRateText = success.data.avrage_rating.toFixed(1);
            //     }else{
            //       //////console.log(success)
            //       this.commonService.ChildToParent({"title":"load", "value" :false });
            //       this.commonService.popToast('error',success.message,1500)
            //     }
            // });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    getNewDate() {
        var today = new Date();
        return today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + (today.getDate())).slice(-2) + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

    }

    sendChatMsg(f: NgForm) {
        try {
            if (f.valid) {
                //console.log(this.chatmesg.msg_body)
                var value = this.chatmesg.msg_body.trim();
                var todays: any = this.getNewDate();
                var todayss: any = ' ';

                var currentdate = new Date();
                var datetime = "Last Sync: " + currentdate.getDate() + "/" +
                    (currentdate.getMonth() + 1) + "/" +
                    currentdate.getFullYear() + " @ " +
                    currentdate.getHours() + ":" +
                    currentdate.getMinutes() + ":" +
                    currentdate.getSeconds();

                ////console.log(datetime);

                var chatHtml = '<div class="chat-tile" >' +
                    '<div class="chat-icon">' +
                    '<img src="' + this.commonService.getToken("photo") + '" width="40" height="40">' +
                    '</div>' +
                    '<div class="chat-desc"><span>' + this.commonService.getToken("fullname") + '</span>' + this.chatmesg.msg_body + '</div>' +
                    '<div class="clearfix"></div>' +
                    '</div>';
                //////console.log(chatHtml)
                $('.apnd_chat').append(chatHtml);
                $('.main-chat').scrollTop($('.main-chat')[0].scrollHeight);
                this.chatmesg.msg_body = '';
                $('#msg_body').focus();
                var data = {};
                var rater = this.answerData.rater;
                data['sender'] = this.userIDs;
                data['job_title'] = this.jobTitleName;
                data['receaver'] = rater;
                data['body_msg'] = value;
                data['conversation_id'] = this.conversationID;
                data['fullname'] = this.commonService.getToken("fullname");
                data['user_photo'] = this.commonService.getToken("photo");
                data['room'] = this.compnayId;
                data['read_status'] = "unread";
                data['noty_type'] = "Chat";
                data['jobId'] = this.jobid;
                data['dicision'] = this.chatmesg.dicision || this.jobDicision;
                data['cand_id'] = this.candUserId;
                data['web_title'] = "New message from " + this.commonService.getToken("fullname");
                data['web_content'] = value;
                //console.log('12121212121212121')
                this.socketService.send('send:message', data);
                //this.addChatMesageToDB(data);
            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    getAllMemberByCompany() {
        var compnay_id = this.compnayId;
        this.commonService.callApi('getall_member_by_company', { "compnay_id": compnay_id }, 'post', false, true).then(success => {
            if (success.status == 1) {
                //////console.log(success)

            } else {

            }
        });
    }

    getConversationId(candid) {
        try {
            var compnay_id = this.compnayId;
            var data = {};
            data['jobpost_id'] = this.jobid;
            data['employer_id'] = this.userIDs;
            data['candidate_id'] = candid;
            this.commonService.callApi('get_conversation_id', data, 'post', false, true).then(success => {
                if (success.status == 1) {
                    //////console.log(success)
                    this.chatListing = success.data.chats;
                    this.conversationID = success.data._id;
                    ////console.log("total",success.total)
                    this.unreadCount = success.total;
                    setTimeout(function() {
                        $('.main-chat').scrollTop($('.main-chat')[0].scrollHeight);
                    }, 100, false);


                    // ////console.log(this.conversationID)

                } else {

                }
            });
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    addChatMesageToDB(data) {

        this.commonService.callApi('add_chat_mesage_in_db', data, 'post', false, true).then(success => {
            if (success.status == 1) {
                //////console.log(success)
                //this.conversationID = success.data._id;

            } else {

            }
        });
    }

    changeDicision(dicision) {
        try {

            ////console.log(dicision)
            var findIndex = _.findIndex(this.candList, { 'user_id': this.candUserId });

            this.jobDicision = dicision;
            this.candList[findIndex].decision = dicision;
            $('.cand' + this.candUserId).removeClass('cand-badge cand-check cand-quest cand-cross cand-no').addClass('cand-badge cand-' + dicision);
            var status = '';
            if (dicision === "quest") {
                status = 'Reviewed';
            } else if (dicision === "no") {
                status = 'Not Reviewed';
            } else if (dicision === "cross") {
                status = 'Not Selected';
            } else if (dicision === "check") {
                status = 'Selected';
            }

            if (dicision != "Select Status") {
                ////console.log({"status":status, "decision":dicision, "applied_id": this.jobAppliedId})
                this.commonService.callApi('update_applied_job_status', { "status": status, "decision": dicision, "applied_id": this.jobAppliedId }, 'post', false, true).then(success => {
                    if (success.status == 1) {
                        ////console.log("dicision",success)
                        //this.conversationID = success.data._id;
                        var titleS = "Applicant status changed By " + this.commonService.getToken('fullname');
                        this.sendSockeNoty('applicantStatusChange:employer', this.jobid, titleS, " ", this.jobAppliedId, this.candUserId, dicision, 'Applicant_Status_Change')

                    } else {

                    }
                });

            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    getTimeAgo(value) {
        try {
            var templates = {
                prefix: "",
                suffix: " ago",
                seconds: "less than a minute",
                minute: "a minute",
                minutes: "%d minutes",
                hour: "an hour",
                hours: "%d hours",
                day: "a day",
                days: "%d days",
                month: "a month",
                months: "%d months",
                year: "a year",
                years: "%d years"
            };
            var template = function(t, n) {
                return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
            };

            var timer = function(time) {
                if (!time)
                    return;
                time = time.replace(/\.\d+/, ""); // remove milliseconds
                time = time.replace(/-/, "/").replace(/-/, "/");
                time = time.replace(/T/, " ").replace(/Z/, " UTC");
                time = time.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400
                time = new Date(time * 1000 || time);

                var now = new Date();
                var seconds = ((now.getTime() - time) * .001) >> 0;
                var minutes = seconds / 60;
                var hours = minutes / 60;
                var days = hours / 24;
                var years = days / 365;

                return templates.prefix + (
                    seconds < 45 && template('seconds', seconds) ||
                    seconds < 90 && template('minute', 1) ||
                    minutes < 45 && template('minutes', minutes) ||
                    minutes < 90 && template('hour', 1) ||
                    hours < 24 && template('hours', hours) ||
                    hours < 42 && template('day', 1) ||
                    days < 30 && template('days', days) ||
                    days < 45 && template('month', 1) ||
                    days < 365 && template('months', days / 30) ||
                    years < 1.5 && template('year', 1) ||
                    template('years', years)
                ) + templates.suffix;
            };

            var elements = document.getElementsByClassName('timeago');
            var fntime = timer(value);

            ////console.log("fntime",fntime)
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    sendSockeNoty(event, jobid, title, content, appid, candid, dicision, noty_type) {
        //console.log('111111111111')
        try {
            var soxData = {};
            soxData['job_title'] = this.jobTitleName;
            soxData['web_title'] = title;
            soxData['web_content'] = content;
            soxData['noty_type'] = noty_type;
            soxData['jobId'] = jobid;
            soxData['applied_id'] = appid;
            soxData['dicision'] = dicision;
            soxData['cand_id'] = candid;
            soxData['user_photo'] = this.commonService.getToken('photo');
            soxData['room'] = this.commonService.getToken('company');
            soxData['_id'] = this.commonService.getToken('auid');
            soxData['user_fullname'] = this.commonService.getToken('fullname');
            this.socketService.send(event, soxData);
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }

    }

    filterByRating(byrate, name, event) {
        try {
            
            this.filterstar = byrate;
            $('.star-drop').removeClass('active');
            $(event.target).addClass('active');
            $('.btn-star').empty().text(name);
            $('.cand-loader').show();
            this.from_noty = false;            
            if (byrate === '0') {
                console.log('1111',this.jobid, '', byrate, this.filterapplied)
                this.getAppliedCandidate(this.jobid, '', 0, this.filterapplied, '')
            } else {
                console.log('2222',this.jobid, '', byrate, this.filterapplied)
                this.getAppliedCandidate(this.jobid, '', byrate, this.filterapplied, '')
            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    filterByApplied(byapplied, name, event) {
        try {
            ////console.log(byapplied)
            $('.app-drop').removeClass('active');
            $(event.target).addClass('active');
            $('.cand-loader').show();
            $('.btn-app').empty().text(name);
            this.from_noty = false;
            this.filterapplied = byapplied
            if (byapplied === '0') {
                this.getAppliedCandidate(this.jobid, '', this.filterstar, 0, '')
            } else {
                var appArry = this.getCurrntWeekMonthYear(byapplied);
                ////console.log('appArry',appArry)
                this.getAppliedCandidate(this.jobid, '', this.filterstar, appArry, '')
            }
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

    clickCandCheckbox(event, mode, user_id?, cand_id?, photo?, decision?, name?, index?, rating?){
        try{
            //console.log(cand_id, index, $(event.target).prop('checked'))
            var element =  $(event.target).parents('.cand-detail-list-item');
            $('.cand-detail-list-item .custom-check').css('display', 'block')
            if(mode === 'all'){

            }else if(mode === 'single'){
                 
                this.showShare = true;
                if($(event.target).prop('checked')){
                    //console.log('111111')
                    $(event.target).parents('.cand-detail-list-item').addClass('cand-scheck');
                    
                    this.sectedCandData.push({
                        'cand_id': cand_id,
                        'photo': photo,
                        'decision': decision,
                        'name': name,
                        'rating': rating                        
                    });

                }else{
                    
                    var findindx = _.findIndex(this.sectedCandData, { "cand_id": cand_id });
                    //console.log('222222', findindx)
                    if(findindx >= 0){
                        this.sectedCandData.splice(findindx, 1)
                    }
                    $(event.target).parents('.cand-detail-list-item').removeClass('cand-scheck');

                    setTimeout($.proxy(function(){
                        console.log(this.sectedCandData)
                        if(this.sectedCandData.length === 0){
                           this.showShare = false; 
                           $('.cand-detail-list-item .custom-check').css('display', 'none')
                        }
                    },this),500);  

                }
            }

                      
        }catch(error){

        }
    }

    clickCandCheckboxAll(event, mode, flag){
        try{
            this.selectShareall = !flag;
            $('.cand-checkbox').prop('checked', flag);
            if(!flag){
                $('.cand-detail-list-item .custom-check').css('display', 'none')
            }
            if(flag){
                this.sectedCandData = [];
                _.forEach(this.candList,$.proxy(function(item){
                    this.sectedCandData.push({
                            'cand_id': item._id,
                            'photo': item.user[0].user_photo,
                            'decision': item.decision,
                            'name': item.user[0].user_fullname,
                            'rating': (item.candrate.length > 0 && item.candrate[0].rating)? item.candrate[0].rating : false
                        })
                },this))
            }else{
                this.sectedCandData = [];
                this.showShare = false;
            }            
        }catch(error){

        }
    }

    seacrhCand(text) {
        try {
            
            if(text && text.trim().length > 1){
               
                console.log('text', text)
                var data = {"search":text, 'job_id':this.jobid};
                const newData = this.candList.filter(item => {
           
                  const itemData =  item.user[0].user_fullname.toLowerCase();
                  const textData = text.toLowerCase();
                    
                  return itemData.includes(textData); //itemData.indexOf(textData) > -1;
                  
                });

                setTimeout($.proxy(function(){
                    console.log('newData', newData)
                    this.candList = newData;
                    this.seacrhcandlistlength = newData.length;
                },this))

                
                // this.commonService.callApi('get_search_cand_listing', data, 'post', false, true).then(success => {
                //     if (success.status == 1) {  

                //     } else {

                //     }
                // });
            }else if(text.trim().length === 0){
                this.candList = this.candListcandBackup;
                this.seacrhcandlistlength = 0;
            }          
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 2000)
        }
    }

    closeCandSearch(){
        try{
            this.candList = this.candListcandBackup;
            this.seacrhcandlistlength = 0;
        }catch(error){

        }
    }

    clickShareCand(){
        try{
            var dataObj = {
                'job_id': this.jobid,
                'candArray': this.sectedCandData
            }
            this.commonService.ChildToSettingPopup({"type":"shareCandpop", dataObj});
        }catch(error){

        }
    }

    ngOnDestroy() {
        try {
            $('body').unbind('click');
            $('body').unbind('mouseenter');
            $('body').unbind('mouseleave');
            $('body').unbind('mouseover');
            $('.tab1act').removeClass('current');

            this.subscriptionForConnect.unsubscribe();
            this.subscriptionForChatData.unsubscribe();
            this.subscriptionForAppliedJOb.unsubscribe();
        } catch (error) {
            this.commonService.popToast('error', "Server Error", 1500)
        }
    }

}