import { Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs';
import * as io from 'socket.io-client';
import {appSetting} from '../app.config';
import { CommonService } from './common.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router , ActivatedRoute} from '@angular/router';

@Injectable()
export class SocketService {
  public socket;
  public token:any ='';
  public _wsUrl :any = appSetting.wsUrl;
  public platformId;

  constructor(private router: Router,private commonService: CommonService, @Inject(PLATFORM_ID) platformId: Object,) {    
    this.token = this.commonService.getToken("accessToken");
    this.platformId = platformId;
  }

  getSoc(){
    return this.socket;
  }

  initSocket(fn): void {
    try{
      if(isPlatformBrowser(this.platformId)){
        //this.socket = io.connect(this._apiUrl, {query: {token: this.token}});
         
        this.socket = io(this._wsUrl, {secure: true,transports: ['websocket']});
        //this.socket = io(this._wsUrl); 
        fn(true);
      }
    }catch(error){
      console.log(error)
    }      
  }

  send(event, data): void {
    //console.log("event",event)
    if(this.socket){
      this.socket.emit(event, data);
    }
  }

  onEvent(event, fn){
    if(this.socket){
      this.socket.on(event, function(data){
        fn(data);
      });
    }        
  }

  disconnectSocket(): void {
    try{
      if(isPlatformBrowser(this.platformId)){
        //this.socket = io.connect(this._apiUrl, {query: {token: this.token}}); 
        //this.socket = io.connect(this._wsUrl , {transports: ['websocket']}); 
        this.socket.disconnect();
      }
    }catch(error){
      console.log(error)
    }      
  }

  sendcb(event, data): void {
    if(this.socket){
      this.socket.emit(event, data, function(cb){
        //console.log("cb");
      });
    }
      
  }

	// public onMessage(){
	//     let observable = new Observable(observer => {
	//       this.socket.on('message', (data: Message) => observer.next(data)); 
	//     })     
	//     return observable;
	// }

  // onEvent(event): Observable<any> {
  //       return new Observable<Event>(observer => {
  //           this.socket.on(event, (data) => observer.next(data));
  //       });
  // }

	

  diff_hours(dt2, dt1){
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));  
  }


}